import { template as template_6e589e6ea2d9465fb02d7584f099244f } from "@ember/template-compiler";
const FKControlMenuContainer = template_6e589e6ea2d9465fb02d7584f099244f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
