import { template as template_6d2260aa50a441709d7e03a1b6355c6d } from "@ember/template-compiler";
const WelcomeHeader = template_6d2260aa50a441709d7e03a1b6355c6d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
