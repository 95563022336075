import { template as template_ed3be7f0ceab40c2999f9afd7e2a5b8d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_ed3be7f0ceab40c2999f9afd7e2a5b8d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
