import { template as template_d9f4e7a8deef4f31bcb3e831e282fedb } from "@ember/template-compiler";
const FKText = template_d9f4e7a8deef4f31bcb3e831e282fedb(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
