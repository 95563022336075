import { template as template_c8066e53a79f48c985c1e28116994d63 } from "@ember/template-compiler";
const SidebarSectionMessage = template_c8066e53a79f48c985c1e28116994d63(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
